import Vue from 'vue'
import VueRouter from 'vue-router'
import Wrapper from '../views/Wrapper.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Wrapper',
    component: Wrapper,
    redirect: '/',
    children:[
      {
        path: '/',
        name: 'Realplayer',
        component: () => import('../views/Real.vue'),                       // 首页
        meta:{
          title:'欢迎使用来自RealNetworks（瑞尔视科技）的RealPlayer播放器'
        }
      },{
        path: '/realplayer',
        name: 'Realplayer',
        component: () => import('../views/Realplayer.vue'),                 // RealPlayer
        meta:{
          title:'RealPlayer-更多媒体体验'
        }
      }, {
        path: '/realplayer-features',
        name: 'RealplayerFeatures',
        component: () => import('../views/RealplayerFeatures.vue'),         // RealPlayer 功能
        meta:{
          title:'RealPlayer-更多媒体体验'
        }
      }, {
        path: '/realplayer-mobile',
        name: 'RealplayerMobile',
        component: () => import('../views/RealplayerMobile.vue'),           // RealPlayer 移动端
        meta:{
          title:'RealPlayer 移动版 来自RealNetworks'
        }
      }, {
        path: '/thankyou',
        name: 'Thankyou',
        component: () => import('../views/Thankyou.vue'),                   // 下载界面
        meta:{
          title:'随时随地看视频 | RealPlayer云空间'
        }
      }, {
        path: '/policy',
        name: 'Policy',
        component: () => import('../views/Policy.vue'),                     // 隐私协议
        meta:{
          title:'RealPlayer 播放器隐私政策'
        }
      }, {
        path: '/terms',
        name: 'Terms',
        component: () => import('../views/Terms.vue'),                      // 服务条款
        meta:{
          title:'RealNetworks|服务条款'
        }
      }, {
        path: '/Subscription',
        name: 'Subscription',
        component: () => import('../views/Subscription.vue'),                      // 服务条款
        meta:{
          title:'RealNetworks|订购条款'
        }
      }, {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () => import('../views/aboutUs.vue'),                      // 公司介绍
        meta:{
          title:'RealNetworks|公司介绍'
        }
      }, {
        path: '/ContractUs',
        name: 'CounTractUs',
        component: () => import('../views/ContractUs.vue'),                      // 联系我们
        meta:{
          title:'RealNetworks|联系我们'
        }
      }
    ]
  }
]

// const router = new VueRouter({
//   mode: 'history',
//   base: '/realplayer/', 
//   routes
// })
const router = new VueRouter({
  routes
})

export default router
