<template>
  <!-- 首页入口 -->
  <div class="wrapper">

    <div class="tpo-nav">

      <div class="leftImg"><img src="@/img/logo.png" alt="" @click="toLink('/')"></div>

      <div class="rightNav">

        <div :class="selectIndex==1?'selectActive':''" @click="checkSelect(1)">
          RealPlayer<span class="xiala"></span>
          <div class="selectDiv" v-if="selectIndex == 1">
            <div @click="toLink('/realplayer')">关于 RealPlayer</div>
          </div>
        </div>

        <div @click="toLink('/realplayer-mobile')">RealPlayer 移动版</div>
        <div @click="toLink('/aboutUs')">公司介绍</div>
        <div @click="toLink('/ContractUs')">联系我们</div>        
        <div @click="toLink(questionLink,false)">问题反馈</div>
        

      </div>

      
      

    </div>
    
    <!-- 单页展示 -->

    <router-view/>
    
    <!-- 页面底部 -->
    
    <Footer></Footer>
  </div>

</template>

<script>
  // @ is an alias @click /src
  import Footer from "@/components/Footer.vue"
  import settingLink from '@/js/global.js'

  export default {
    name: 'Wrapper',
    components:{
      Footer
    },

  data(){

    return{
      selectIndex:null,  // 下拉框
      questionLink:settingLink.questionLink,

    }
  },

  created:()=>{
  },

  methods:{

    // 跳转路由
    
    toLink(routers,type=true){

      if(routers != this.$router.currentRoute.fullPath && type){

        this.$router.push(routers);

      }else if(type == false){
          window.open(routers,'_blank');

      }

    },

    // 选中下拉框
    checkSelect:function(seIndex){
      if(this.selectIndex == seIndex){
        this.selectIndex = null
      }else{
        this.selectIndex = seIndex
      }
    }

  }

}

</script>
<style scoped>
@media screen and (min-width: 480px) {
  .mobile-type{display: none !important;}
  .wrapper{
    width: 100%;
    min-height: 500px;
  }
  /* 头部导航 */
  .tpo-nav{display: flex;align-items: center;justify-content: space-between;height: 68px;box-shadow: 0 1px 5px #999999;box-sizing: border-box;
  }

  .leftImg{margin-left: 15px;cursor: pointer;}
  /* 头部-右侧路由 */
  .rightNav{display: flex;color: #333333;box-sizing: border-box;}
  .rightNav>div{margin-right: 20px;height: 68px;display: flex;color: #333333;align-items: center;box-sizing: border-box;cursor: pointer;}
  
  /* 下拉框 */
  .selectActive{
    color: #00a1ff !important;
    border-bottom: 5px solid #00a1ff !important;
    transition: all .10s ease-in-out;
    box-sizing: border-box;
    position: relative;
  }
  .selectDiv{
    width: 180px;position: absolute;top: 68px;

    padding: 10px 10px 20px;
    margin: 2px 0 0;

    background-color: #e3e3e3;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    
    display: flex;flex-direction: column;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    font-size: 14px;
  }
  .selectDiv>div{height: 40px;text-align: left;line-height: 40px;color: #444 !important;}
  .selectDiv>div:hover{
    background: #EAEAEA;
    border-radius: 4px;
    color: #00a1ff !important;

  }
  .xiala{
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
}
@media screen and (min-width: 320px) and (max-width:480px){
  .pc-type{display: none !important;}
  .wrapper{
    width: 100%;
    min-height: 500px;
  }
  /* 头部导航 */
  .tpo-nav{display: flex;align-items: center;justify-content: space-between;height: 68px;box-shadow: 0 1px 5px #999999;box-sizing: border-box;margin-bottom: 20px;}

  .leftImg{margin-left: 15px;cursor: pointer;}
  /* 头部-右侧路由 */
  .rightNav{display: flex;color: #333333;box-sizing: border-box;font-size: 14px;}
  .rightNav>div{margin-right: 20px;height: 68px;display: flex;color: #333333;align-items: center;box-sizing: border-box;cursor: pointer;}
  
  /* 下拉框 */
  .selectActive{
    color: #00a1ff !important;
    border-bottom: 5px solid #00a1ff !important;
    transition: all .10s ease-in-out;
    box-sizing: border-box;
    position: relative;
  }
  .selectDiv{
    width: 180px;position: absolute;top: 68px;

    padding: 10px 10px 20px;
    margin: 2px 0 0;

    background-color: #e3e3e3;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    
    display: flex;flex-direction: column;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    font-size: 14px;
  }
  .selectDiv>div{height: 40px;text-align: left;line-height: 40px;color: #444 !important;}
  .selectDiv>div:hover{
    background: #EAEAEA;
    border-radius: 4px;
    color: #00a1ff !important;

  }
  .xiala{
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
}
  
</style>
