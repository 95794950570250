
const settingLink = {
    
    // googleLink:'https://play.google.com/store/apps/details?id=com.real.RealPlayerMobile',                     // 谷歌商店链接
    googleLink:'https://realplayer-download.realplayer.cn/app/RealPlayer.apk',                                // 安卓商店链接
    appStoreLink:'https://apps.apple.com/app/realplayer-mobile/id1578950397',                                 // 苹果商店链接

    
    payLink1:'https://order.realplayer.cn/rn/zh/cn?p=7001',         // ￥30.00
    payLink2:'https://order.realplayer.cn/rn/zh/cn?p=7002',         // ￥18.00
    payLink3:'https://order.realplayer.cn/rn/zh/cn?p=7101',         // ￥18.00/mo
    payLink4:'https://order.realplayer.cn/rn/zh/cn?p=7102',         // ￥98.00/yr
    
    downLoadLink_windows_real:'https://realplayer-download.real.com/free/windows/installer/stubinst/stub/rp22/T22END01/RealPlayer.exe',                // 下载地址---windows-real
    downLoadLink_windows_realplayer:'https://realplayer-download.real.com/free/windows/installer/stubinst/stub/rp22/T22END01/RealPlayer.exe',          // 下载地址---windows-realplayer
    downLoadLink_windows_realplayer_features:'https://realplayer-download.real.com/free/windows/installer/stubinst/stub/rp22/T22END01/RealPlayer.exe', // 下载地址---windows-realplayer_features
    downLoadLink_windows_realplayer_pricing:'https://realplayer-download.real.com/free/windows/installer/stubinst/stub/rp22/T22END01/RealPlayer.exe',  // 下载地址---windows-realplayer_pricing
    downLoadLink_windows_realplayer_mobile:'https://realplayer-download.real.com/free/windows/installer/stubinst/stub/rp22/T22END01/RealPlayer.exe',   // 下载地址---windows-realplayer_mobile
    



    downLoadLink_mac:'https://apps.apple.com/app/realplayer-mobile/id1578950397',                             // 下载地址---mac


    downLoadLink_iphone:'https://apps.apple.com/app/realplayer-mobile/id1578950397',                          // 下载地址---iphone
    downLoadLink_andriod:'https://play.google.com/store/apps/details?id=com.real.RealPlayerMobile',                      // 下载地址---andriod


    // Footer Link
    // Support:'https://customer.realplayer.cn/hc/zh-cn',                                                        // 技术支持--旧版本
    Support:'https://www.real.com/support',                                                        // 技术支持
    ContactUs:'https://cn.realnetworks.com/contact',                                                          // 联系我们
    RealNetworks:'https://cn.realnetworks.com',                                                                // RealNetworks
    Terms:'http://www.realnetworks.com/terms?lang=en',                                                        // 服务条款链接（已废弃）
    PrivacyPolicy:'http://www.realnetworks.com/about-us/legal/privacy.aspx',                                  // 隐私协议    （已废弃）
    icpLink:'https://beian.miit.gov.cn/#/Integrated/index',                                                   // icp网址---京ICP备2021033840号-3
    ZOL:'https://xiazai.zol.com.cn',
    questionLink:'https://www.real.com/support',                                                                                          // 反馈问题列表
}

export default settingLink;
