<template>
  <div class="footer">
    <footer>
      <div class="clearfix"></div>
      <div class="col-xs-12 padding-top-50px">
        <ul class="footer-nav">
          <li><a :href="Support">技术支持</a></li>
          <li><a @click="openLink(questionLink)">问题反馈</a></li>
        </ul>
      </div>
      <div class="clearfix visible-xs"></div>
      <div class="col-sm-12">
        <ul class="terms">
          <li><div class="linkPage" @click="openLink(icpLink)"> 京ICP备2021033840号-3 </div></li>
          <li><div class="linkPage" @click="openLink('/terms',true)">服务条款</div></li>
          <li><div class="linkPage" @click="openLink('/policy',true)">隐私政策</div></li>
          <li class="copyright"><em> 2022 RealNetworks  北京瑞尔视科技有限公司  版权所有</em></li>
        </ul>
      </div>
      <div class="col-xs-12 padding-top-50px">
        <ul class="footer-nav">
          <li><a>友情链接：</a></li>
          <li><a :href="ZOL">ZOL应用下载</a></li>
        </ul>
        </div>
    </footer>
  </div>
</template>
<script>
  import settingLink from '@/js/global.js'
  export default {
    data(){
      return {
        Support:settingLink.Support,
        ContactUs:settingLink.ContactUs,
        Terms:settingLink.Terms,
        PrivacyPolicy:settingLink.PrivacyPolicy,
        icpLink:settingLink.icpLink,
	ZOL:settingLink.ZOL,
        questionLink:settingLink.questionLink,
      }
    },
    methods:{
      openLink:function(url,type=false){
        if(type){
          if(url != this.$router.currentRoute.fullPath){
            this.$router.push(url);
          }
        }else{
          window.open(url,'_blank');
        }
      }
    }
  }
</script>
<style scoped>
footer {
  border-top: 1x solid #000;
  padding-bottom: 1.5em;
}

.terms {
  font: normal normal 10px Red Hat Display !important;
  letter-spacing: 0;
  color: #525252;
  opacity: 1;
  padding-top: 1em;
  padding-bottom: 10px;
  text-align: center;
  color: #525252 !important;
}
a {
  color: #525252;
  text-decoration: none;
}
.linkPage{color: #525252;cursor: pointer;}
.terms > li {
  border-right: 1px solid grey;
  display: inline-block;
  height: 1em;
  line-height: 1em;
  padding-bottom: 0;
  padding-left: 1em;
  padding-right: 1em;
}
.footer-nav {
  padding-top: 50px;
  margin-bottom: 14px;
  text-align: center;
  font: normal normal 14px/16px Red Hat Display !important;
}
.footer-nav li {
  display: inline;
  padding: 0 10px;
}
.footer-nav a {
  cursor:pointer;
  color: #00a1ff;
}
</style>
