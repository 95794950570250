<template>
  <div id="app">
   <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'Real',
  mounted:function(){
    var equipment = this.appSource();// 当前设备
    // console.log(equipment)
    window.localStorage.setItem('type',equipment); // 设置当前设备类型
    
    
    // var type = window.localStorage.getItem('type'); // 获取当前设备类型
    // console.log(type)
  },
  methods:{
    appSource() {
      const u = navigator.userAgent;
      let flag = u.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i); // 是否手机/电脑
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);  // 手机端型号
      var isMac = /macintosh|mac os x/i.test(navigator.userAgent); // 是否mac
      // 是否手机端
      if (flag) {
        if (isiOS) {
          return "ios";
        } else {
          return "andriod";
        }
      } else {
        if(isMac){
          return "mac";
        }else {
          return "pc"
        }
      }

    }
  }
}
</script>
<style>
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,fieldset,input,textarea,p,blockquote,th,td,img {padding:0;margin:0;}

/* 文字 */
.t-c{text-align: center;}
.t-r{text-align: right;}

/* 颜色蓝色 */
.c-b{color: #00a7ff!important;}

.p_{cursor: pointer;}
.p_:hover{text-decoration: underline;}
</style>
